.splash-component {
    width: 100%;
    height: 100%;
    background-color: #fff;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .version {
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align-last: center;
        font-size: 14px;
        font-weight: 600;
        opacity: 0.5;
    }
}
