.drawer-component {
}
.drawer-content {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding: 20px;
    box-sizing: border-box;

    .logo {
        width: 100px;
        height: 15px;
        object-fit: contain;
    }

    .item{
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        margin-top: 20px;
        color: inherit !important;
        text-decoration: none;

        svg{
            margin-right: 10px;
        }

        .download{
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 5px;
            
        }
    }
}
