@import "components/elements/colors.scss";

.button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $primary;
    border-radius: 14px;
    box-shadow: 2px 2px 4px #b8b8b8;

    &.secondary {
        background-color: $light;

        .text-wrapper {
            color: $text_secondary;
        }
    }

    .image-wrapper {
        padding: 10px;
        display: flex;
        align-items: center;
        border-radius: 14px;
        margin: 10px;

        img {
            width: 45px;
            height: 45px;
        }
    }

    .text-wrapper {
        height: 100%;
        color: $text_primary;
        font-weight: 500;
        margin-right: auto;
        justify-content: space-evenly;

        .title {
            font-size: 16px;
            margin: 5px 0;
            font-weight: 600;
        }
shad
        .subtitle {
            font-size: 14px;
            margin: 5px 0;
        }
    }
}
