@import "components/elements/colors.scss";

.header-component {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.isPremium {
        background-color: $terminix;
        padding-top: 30px;
        img {
            display: none;
        }
        .back,
        .drawer {
            color: $light;
            svg {
                color: $light;
            }
        }
    }

    img {
        width: 120px;
        height: 15px;
        object-fit: contain;
    }

    .back {
        position: absolute;
        left: 20px;

        svg {
            color: black;
            margin: 0px 5px 0px 0px;
            font-size: 14px;
        }

        span {
            margin-bottom: 0px;
            margin-left: -2px;
            padding-right: 5px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .drawer {
        position: absolute;
        right: 20px;
        margin-top: 4px;
    }
}
