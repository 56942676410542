@import "components/elements/colors.scss";

.checkbox-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    .checkboxLabel {
        color: #838691;
    }

    .checkbox {
        height: 20px;
        width: 20px;
        border: 1px solid #838691;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;

        &.checkbox-checked {
            color: $light;
            background-color: #080c22;
        }
    }
}
