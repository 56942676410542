@import "components/elements/colors.scss";

.filter-component {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .modal-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .close {
            position: absolute;
            left: 0;
            font-size: 28px;
            top: 5px;
        }
    }

    .modal-content {
        margin-top: 20px;

        .order-by-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;

            .order-filter-tag {
                align-items: center;
                background-color: $light;
                border: 1px solid $primary;
                border-radius: 15px;
                padding: 5px 15px;
                margin-right: 10px;
                color: $primary;
                font-weight: 500;
                font-size: 14px;

                &.active {
                    background-color: $primary;
                    color: $light;
                }
            }
        }

        .css-yk16xz-control {
            background-color: #f7f7f7;
            border: 1px solid #f7f7f7;
        }
    }

    .modal-bottom {
        margin-top: auto;
        border-top: 1px solid #f7f7f7;
    }
}
