@import "../colors.scss";

.partner-rate-wrapper {
    width: 90vw;
    background-color: $light;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    box-sizing: border-box;

    .img-wrapper {
        justify-content: center;
        align-items: center;
        border-radius: 14px;
    }

    .title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin: 20px 0 10px 0;
    }

    .subtitle {
        font-size: 14px;
        text-align: center;
    }

    .mood {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 20px;
    }

    .buttons-wrapper {
        width: 100%;
        align-items: center;
        
        .button {
            box-sizing: border-box;
            text-align: center;
            border-top: 1px solid $disabled;
            width: 100%;
            padding: 20px;
            text-transform: capitalize;
        }
    }
}
