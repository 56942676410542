.search-component {
    padding: 7px 10px 7px 10px;
    background-color: #f2f2f2;
    box-shadow: 0px 0px 8px #f3f3f3;
    border-radius: 18px;
    width: 100%;
    box-sizing: border-box;
    margin: 20px 0px;
    display: flex;
    align-items: center;

    input {
        border: none;
        width: 100%;
        height: 100%;
        background-color: #f2f2f2;
        font-size: 15px;
        padding: 0px 5px;

        &:focus {
            outline: none;
        }
    }
}
