@import "components/elements/colors.scss";

.feedback-conponent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .content {
        padding: 20px;

        .subtitle {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .comments-input {
            padding: 10px;
            width: 100%;
            min-height: 200px;
            box-sizing: border-box;
            border-radius: 6px;
            margin-bottom: 20px;
        }

        .success {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}
