.tutorial-screen {
    width: 100%;
    height: 100%;

    .swiper-container,
    .swiper-wrapper {
        width: 100%;
        height: 100%;

        .swiper-slide {
            background-color: #080c22;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .mockup {
                flex: 7;
                overflow: hidden;
                padding: 20px;
                padding-bottom: 0px;
                position: relative;

                .gradient {
                    position: absolute;
                    z-index: 9;
                    height: 100%;
                    width: 100%;
                    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), #080c22);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .details {
                padding: 20px;
                padding-top: 0px;
                color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                p {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
