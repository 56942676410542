$primary: #50e3c2;
$accent: #ed4300; //color used for extra highlight
$light: #fff;
$disabled: #d1d1d1;
$text_primary: #222;
$text_secondary: #555;
$line: #ebebeb;
$background_light: #f4f4f4;
$background_dark: #080c22;
// $placeholder: rgba(12, 34, 0.55);
$neutral: #ffeb3b;
$error: #ed4300;
$errorBackground: #f3dede;
$terminix: #20aa4d;
