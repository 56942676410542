@import "../colors.scss";

.trusted-terminator-wrapper {
    display: flex;
    justify-content: flex-start;
    background-color: $primary;
    padding: 3px 0;
    padding-left: 4px;
    padding-right: 7px;
    border-radius: 5px;
    flex-direction: row;

    .trusted-text {
        color: $light;
        font-weight: bold;
        font-size: 13px;
        margin-left: 5px;
    }
}
