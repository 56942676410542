.review-component {
    display: flex;
    padding: 10px 0px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f7f7f7;

    .picture {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
            margin-left: 2px;
            margin-right: 0px;
        }
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-bottom: 5px;
        }
    }

    .comment {
        flex: 1;
        padding-left: 20px;
    }
}
