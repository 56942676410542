@import "../../elements/colors.scss";
.pest-controller-details-conponent {
    width: 100%;
    height: 100%;

    .colored-background {
        position: absolute;
        height: 50%;
        top: 0;
        width: "100%";
        background-color: $terminix;
        z-index: -1;
    }

    .content {
        flex: 1;
        width: 100%;
        padding-bottom: 130;
        background-color: "#fff";

        svg {
            font-size: 20px;
            color: #50e3c2;
            margin-right: 5px;
        }

        .cover {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            &.premium {
                flex-direction: row;
                margin-bottom: 20px;
                align-items: center;
                justify-content: flex-start;
                text-align: left;

                img {
                    margin-right: 10px;
                }
                h2 {
                    margin-bottom: 5px;
                    margin-top: 0px;
                }
            }

            img {
                width: 70px;
                height: 70px;
                border-radius: 50%;
            }
        }

        .reviews-wrapper {
            padding-bottom: 60px;
            position: relative;

            .reviews {
                margin-left: 10px;
                display: flex;
                align-items: center;
            }
        }

        .skills {
            display: flex;
            flex-wrap: wrap;

            .skill {
                background-color: $background_light;
                border-radius: 26px;
                height: 25px;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                margin-right: 10px;
                margin-top: 5px;
                display: flex;
                font-weight: 600;
            }
        }
    }
}
