@import "components/elements/colors.scss";

.email-to-entomologist {
    .content {
        padding: 20px;

        .app-icon-wrapper {
            width: 100%;
            height: 150px;

            margin-top: 10px;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                width: 110px;
                height: 110px;
            }

            .appname {
                font-size: 14px;
                font-weight: 600;
                padding: 15px 0;
            }
        }

        .title {
            margin-top: 20px;
            font-size: 20px;
            font-weight: bold;
        }

        .description {
            margin: 15px 0;
            color: $text_primary;
            font-size: 15px;
            line-height: 25px;
        }

        .button {
            .children-wrapper {
                .icon-wrapper {
                    margin: 0;
                }
            }
        }
    }
}
