@import "../colors.scss";

.recommended-modal-wrapper {
    width: 90vw;
    background-color: $light;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 30px;
    box-sizing: border-box;

    .title {
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        padding: 0 20px;
    }

    .subtitle {
        color: #555;
        text-align: center;
        font-size: 14px;
        padding: 0 20px;
        line-height: 2;
    }

    img {
        height: 25px;
        margin: 20px;
    }

    .terminixButton {
        background-color: $terminix;
        margin: 20px 0 10px 0;
    }

    .others-managers {
        text-decoration: none;
        font-weight: bold;
        margin-top: 10px;
    }
}
