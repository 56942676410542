.chat-component {
    width: 100%;
    height: 100%;

    .logo {
        width: 150px;
        margin-bottom: 20px;
    }

    .reopen-chat {
        margin-top: 20px;
        font-size: 12px;

        .blue {
            color: blue;
        }
    }
}
