@import "components/elements/colors.scss";

.filter-component {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    z-index: 999999;
    position: absolute;
    background-color: $light;

    .modal-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .close {
            position: absolute;
            left: 0;
            font-size: 28px;
            top: 5px;
        }
    }

    .modal-content {
        margin-top: 20px;

        .mood {
            margin: 0px 10px;

            svg {
                font-size: 40px;
            }
        }
    }

    .inputWrapper {
        padding: 20px 0px;

        textarea{
            margin-top: 20px;
            width: 100%;
            min-height: 100px;
            border-radius: 10px;
            padding: 10px;
            box-sizing: border-box;
        }
    }

    .modal-bottom {
        margin-top: auto;
        border-top: 1px solid #f7f7f7;
    }
}
