@import "../colors.scss";

.button-component {
    width: 100%;
    padding: 13px 10px 12px 10px;
    border: none;
    border-radius: 30px;
    background-color: $primary;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    display: block;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;

    svg {
        margin-right: 10px;
        font-size: 22px;
    }

    &.secondary {
        background-color: #fff;
        color: $text_primary;
        border: 1px solid $background_dark;
        padding: 11px 10px 10px 10px;
        font-weight: 600;
    }

    &.text {
        background-color: #fff;
        color: $primary;
        border: none;
        padding: 11px 10px 10px 10px;
        font-weight: 600;
    }

    &.terminix {
        background-color: $terminix;
    }

    &:focus {
        outline: none;
    }

    &.small {
        padding: 7px 5px 6px 5px;
        font-size: 14px;
    }

    .children-wrapper {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-wrapper {
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}
