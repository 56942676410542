@import "components/elements/colors.scss";

.camera-component {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .wrapper {
        flex: 1;
        overflow-y: scroll;

        .camera-fullscreen {
            width: 100%;
            height: 100%;

            & > div {
                width: 100%;
                height: 100%;
            }
            // .sc-AxirZ > .sc-AxjAm > .sc-AxiKw {
            //     position: absolute !important;
            //     width: 90%;
            //     top: 40vh;
            //     padding: 20px;
            //     text-align: center;
            //     color: red;
            // }
        }

        .react-html5-camera-photo {
            width: 100%;
            height: 100%;
        }

        .camera-overlay {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            // background-color: blue;

            .bottom-options-confirm {
                flex: 4;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 99;

                .picture-frame-bottom {
                    color: $primary;
                    width: 100%;
                    padding: 20px;
                    background-color: #fff;
                    border-radius: 0 0 20px 20px;
                    margin-top: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 700;

                    box-sizing: border-box;

                    .reset-pickture {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            .upper-options {
                flex: 4;
                background-color: rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                z-index: 99;

                .picture-frame-top {
                    height: 60px;
                    width: 100%;
                    padding: 20px;
                    background-color: #fff;
                    border-radius: 20px 20px 0 0;
                    margin-top: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    font-size: 15px;
                    font-weight: 700;

                    box-sizing: border-box;
                }
            }

            .circle-wrapper {
                width: 100vw;
                height: 80vw;
                position: relative;
                --border-style: 2px solid white;
                --border-space: 20px;

                .uploaded-pic {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .overlay-element {
                    position: absolute;
                    width: 80px; /* controls the length of the borders */
                    height: 40px; /* controls the height of the borders */
                }

                .top-left {
                    border-left: var(--border-style);
                    border-top: var(--border-style);
                    top: var(--border-space);
                    left: var(--border-space);
                }

                .top-right {
                    border-right: var(--border-style);
                    border-top: var(--border-style);
                    top: var(--border-space);
                    right: var(--border-space);
                }

                .bottom-left {
                    border-left: var(--border-style);
                    border-bottom: var(--border-style);
                    bottom: var(--border-space);
                    left: var(--border-space);
                }

                .bottom-right {
                    border-right: var(--border-style);
                    border-bottom: var(--border-style);
                    bottom: var(--border-space);
                    right: var(--border-space);
                }
            }

            .footer-options {
                flex: 6;
                background-color: rgba(0, 0, 0, 0.5);
                display: flex;
                justify-content: center;
                z-index: 9;

                .error {
                    margin-top: 20px;
                    color: red;
                    font-weight: 800;
                }
            }

            .searching {
                display: flex;
                margin-top: 20px;
                align-items: center;
                justify-content: center;

                div {
                    margin: 0px 10px !important;
                }

                span {
                    margin-left: 10px;
                    color: white;
                }
            }

            .message {
                position: absolute;
                padding-top: 220px;
                color: #fff;
                font-weight: 600;
            }

            .bottom-options {
                position: absolute;
                width: 100%;
                background-color: black;
                height: 100px;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 99;

                .gallery {
                    position: absolute;
                    bottom: 20px;
                    left: 10%;
                    color: #fff;
                    font-weight: 600;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .redirect-pest-pros {
                    position: absolute;
                    bottom: 20px;
                    right: 10%;
                    color: #fff;
                    font-weight: 600;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .takePicture {
                    width: 50px;
                    height: 50px;
                    background-color: white;
                    border-radius: 50%;
                    border: 7px solid rgba(0, 0, 0, 0.6);
                }
            }
        }

        .insect-detected {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .identified-bug-image {
                width: 100%;
                flex: 0 0 200px;
                overflow: hidden;
                padding: 0 20px;
                box-sizing: border-box;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 20px;
                }

                .threat-level {
                    position: absolute;
                    right: 30px;
                    bottom: 10px;
                }
            }

            .identified-bug-text {
                width: 100%;
                flex: 1;
                box-sizing: border-box;

                .identified-bug-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 20px 0;
                    padding: 0 20px;

                    h2 {
                        font-weight: 800;
                        font-size: 20px;
                        margin-top: 0;
                    }
                }

                .description {
                    padding: 0 20px;
                    margin-bottom: 20px;
                    line-height: 25px;
                    opacity: 0.7;

                    .show-more-text {
                        font-size: 13px;
                        color: $primary;
                        margin-top: 50px;
                        font-weight: bold;
                    }
                }

                .email-input {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                .separator {
                    display: flex;
                    align-items: center;
                    margin: 20px 0px 10px 0px;
                    .line {
                        flex: 1;
                        border-top: 1px solid #525252;
                    }
                    span {
                        margin: 0px 10px;
                    }
                }

                .buttons-wrapper {
                    width: 100%;

                    .line-wrapper {
                        height: 8px;
                        flex: 1;
                        background-color: $line;
                        margin: 20px 0;
                    }

                    .prevent-steps-wrapper {
                        width: 100%;
                        padding-top: 0;

                        .prevent-icon-wrapper {
                            width: 100%;
                            padding-bottom: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .prevent-image {
                                width: 19px;
                                height: 19px;
                            }

                            .prevent-text {
                                font-size: 16px;
                                padding-left: 6px;
                            }
                        }

                        .step-text {
                            opacity: 0.7;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }

        .photo-preview {
            width: 100%;
            height: 100%;
            background-color: black;
        }
    }
}

.upload-image-component {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .modal-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .close {
            position: absolute;
            left: 0;
            font-size: 28px;
            top: 5px;
        }
    }

    .modal-content {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;

        .react-crop-wrapper {
            display: flex;
            flex-direction: column;
            min-height: 0;
            flex: 1;
            margin: 20px 0;
            position: relative;

            .crop-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: black;
                top: 0;
                left: 0;
                opacity: 0.3;
            }
        }
    }

    .modal-bottom {
        margin-top: auto;
        border-top: 1px solid #f7f7f7;
    }
}

.camera-tips {
    color: white;
    max-width: 70%;

    .tip {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        svg {
            font-size: 24px;
            color: #50e3c2;
        }

        .title {
            color: #50e3c2;
            font-weight: bold;
        }
    }
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
    z-index: 9999999999 !important;
    border-radius: 40px 40px 0 0 !important ;
}

.bottom-sheet-wrapper {
    padding-bottom: 20px;
    
    .title {
        font-weight: bold;
        padding-bottom: 20px;
        padding-top: 10px;
        text-align: center;
    }

    .buttons-wrapper {
        padding: 20px;
        .bottom-sheet-options {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            &.bottom-sheet-options:last-child {
                margin-bottom: 0;
            }

            .icon {
                width: 30px;
                height: 30px;
                border-radius: 8px;
                border: 1px solid $background_dark;
                background-color: $background_dark;
                margin-right: 20px;
                padding: 5px;
            }

            .chrome {
                background-color: $light;
                border: 1px solid $light
            }

            .content {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                    font-weight: bold;
                    padding-bottom: 0px;
                    padding-top: 0px;
                    text-align: center;
                }

                .button-component {
                    width: 100px;
                    padding: 8px 0;
                }

                .custon-button {
                    background-color: #fff;
                    color: #222;
                    border: 1px solid #00000030;
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }
}
