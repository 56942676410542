@import "../../elements/colors.scss";

.radio-button-custon-wrapper {
    width: 100%;
    padding: 10px;
    background-color: $light;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    box-shadow: 1px 1px 2px #00000030;
    margin-bottom: 10px;
    box-sizing: border-box;

    &.checked {
        font-weight: 600;
        box-shadow: 3px 3px 2px #00000060;
    }

    .checked-icon {
        margin: 2px;
        border-radius: 50%;
        font-size: 16px;
        background-color: $primary;
        color: $light;
        padding: 5px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .checkbox-icon {
        border-radius: 4px !important;
    }

    .unchecked-icon {
        width: 22px;
        height: 22px;
        border: 2px solid #00000033;
        margin: 2px;
        border-radius: 50%;
    }
}
