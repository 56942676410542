@import "../../elements/colors.scss";

.newJob-wrapper {
    background-image: $background_light;

    .title {
        font-weight: 600;
        font-size: 18px;
    }

    .subtitle {
        color: $text_secondary;
        font-size: 16px;
        margin: 10px 0 20px 0;
        font-weight: 500;
    }
    .content {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0 20px;

        .basic-infos-wrapper {
            height: 70px;
            display: flex;
            align-items: center;
            flex-direction: row;

            .photo-wrapper {
                width: 70px;
                height: 70px;
                margin-right: 20px;
                border-radius: 11px;
            }

            .info-details {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
        }

        .separator {
            width: 100%;
            height: 1.5px;
            background-color: $line;
            margin-top: 15px;
            margin-bottom: 20px;
        }

        .selections {
            padding: 20px 0;
            // display: flex;
            // justify-content: space-evenly;
            // align-items: center;
        }
    }
}
