@import "../../elements/colors.scss";

.pest-controllers-result-wrapper {

    .container {
        padding: 0 20px;

        .confirm-message-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0 40px;
            padding-top: 20px;

            .title {
                text-align: center;
                font-weight: bold;
                margin-bottom: 20px;
                font-size: 18px;
            }
        }
    }

    .pests-controllers-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
}
