@import "components/elements/colors.scss";

.pest-card-component {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-width: 1px;
    border-radius: 15px;
    box-shadow: 1px 1px 4px #00000033;

    .card-info {
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        .card-photo-wrapper {
            justify-content: "flex-start";
            align-items: "flex-start";
            flex-grow: 0;
            margin-right: 15px;
            width: 60px;
            height: 60px;
            border-radius: 50px;
            overflow: "hidden";

            .card-photo {
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }
        }

        .card-content {
            flex: 1;

            .title-wrapper {
                margin-bottom: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .name {
                    flex: 1;
                    font-size: 15px;
                    line-height: 17px;
                    color: $background_dark;
                    padding-top: 5px;
                    font-weight: bold;
                }
            }

            .reviews {
                flex-shrink: 0;
                padding-top: 7px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .reviews-text {
                    font-size: 13px;
                    line-height: 13px;
                    color: $background_dark;
                    font-weight: bold;
                }

                .reviews-star {
                    padding: 0 2px;
                }

                .reviews-count {
                    color: $background_dark;
                    font-size: 12px;
                    line-height: 12px;
                }
            }

            .icon-text-wrapper {
                display: flex;
                flex-direction: row;
                margin-bottom: 5px;

                .icon-text {
                    margin-right: 7px;
                    padding-top: 2px;
                    padding-left: 3px;
                }

                .icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                }
            }

            .coverage-wrapper {
                flex: 1;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 5px;
            }
        }
    }

    .badge-insect {
        background-color: $background_light;
        border-radius: 26px;
        height: 25px;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        margin-right: 10px;
        margin-top: 5px;
        display: flex;
        font-weight: 600;
    }

    .actions {
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        align-items: center;

        .action-button {
            flex: 1;
            margin-right: 10px;
            height: 40px;
        }
    }
}
