@import "../../elements/colors.scss";

.new-job-area-wrapper {
    background-color: $light;

    .skip-button {
        font-weight: bold;
        color: $primary;
    }

    .title {
        font-weight: 600;
        font-size: 18px;
    }
    
    .content-wrapper {
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;

        .location-input {
            background-color: #d1d1d170;
            box-sizing: border-box;
            border: none;
            outline: none;
            border-radius: 4px;
            font-size: 16px;
            padding: 10px;
            width: 100%;
            margin: 10px 0;
        }

        .slider {
            padding-top: 10px;

            .min-max-ft {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 600;
                font-size: 12px;
                padding-bottom: 10px;
            }

            .MuiSlider-root {
                &.MuiSlider-colorPrimary {
                    color: $primary;
                    border-radius: 50px;
                }

                .MuiSlider-rail {
                    height: 6px;
                    color: $disabled;
                    border-radius: 50px;
                }

                .MuiSlider-track {
                    border-radius: 50px;
                    height: 6px;
                }

                .MuiSlider-thumb {
                    width: 26px;
                    height: 26px;
                    margin-top: -10px;
                    margin-left: -16px;
                    color: $light;
                    box-shadow: 0px 0px 4px #00000030;
                }

                .PrivateValueLabel-circle-4 {
                    background-color: #00000000;
                    color: $text_primary;
                    display: block;
                    transform: none;
                    border-radius: 0;
                    margin-left: 14px;
                    text-align: center;
                    height: unset;
                }

                .PrivateValueLabel-offset-3 {
                    top: -5px;
                }

                .PrivateValueLabel-label-5 {
                    color: $text_primary;
                    font-weight: 600;
                }
            }
        }

        .title {
            font-weight: 600;
            font-size: 18px;
        }

        .subtitle {
            font-weight: 600;
            font-size: 16px;
        }

        .selections {
            width: 100%;
            flex-wrap: wrap;
            padding: 20px 0;
        }

        .location-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;

            .use-location {
                font-size: 12px;
                font-weight: 600;
                margin-left: 5px;
            }

            .loading-lottie{
                padding-left: 10px;
                padding-right: auto;
            }
        }

        .invert-icon {
            transform: scaleX(-1);
            color: $primary;
        }
    }
}
