@import "../colors.scss";

.threat-component {
    width: fit-content;
    padding: 6px 11px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-size: 12px;
    margin-bottom: 3px;
    white-space: nowrap;
    font-weight: 600;

    &.low {
        background-color: $primary;
    }

    &.medium {
        background-color: #ffc940;
    }

    &.high {
        background-color: #ff4040;
    }

    &.na {
        background-color: #bbbbbb;
    }
}
