@import "../../elements/colors.scss";

.squad-button-wrapper {
    width: 160px;
    height: 160px;
    padding: 10px;
    background-color: $light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 1px 1px 4px #00000033;

    position: relative;

    img {
        width: 70px;
        height: 70px;
        margin-bottom: 20px;
    }

    &.checked {
        font-weight: 600;
        box-shadow: 2px 2px 4px #00000080;
    }

    .checkedIcon {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 30px;
        color: $primary;
        font-weight: bolder;
    }
}
