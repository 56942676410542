@import "components/elements/colors.scss";

.pest-controllers-conponent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .content {
        padding: 20px;

        .filter-items {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 10px;
            
            .filter-tag {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary;
                color: $light;
                padding: 5px 8px;
                border-radius: 15px;
                margin-bottom: 8px;
                margin-right: 5px;
                font-size: 14px;
                max-width: 100%;
                box-sizing: border-box;

                .filter-tag-close {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                }

                .filter-tag-name {
                    font-weight: 500;
                    margin-left: 5px;
                    white-space: nowrap;
                    margin-right: 5px;
                }

                .filter-tag-value {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
}
