@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

html,
body,
#root,
.App {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

section {
    padding: 10px 0px;
}

h2 {
    font-weight: 600;
    font-size: 24px;
    margin: 10px 0px 0px 0px;
}

h3 {
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0px;
}

h4 {
    font-weight: 400;
    font-size: 16px;
    margin: 10px 0px;
}

p {
    margin: 0;
}

.bottom10 {
    margin-bottom: 10px;
}

.textCenter {
    text-align: center;
}

.flex {
    display: flex;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.align-start {
    align-items: flex-start;
}
.justify-start {
    justify-content: flex-start;
}
.align-end {
    align-items: flex-end;
}
.align-center {
    align-items: center;
}

.error {
    color: #ed4300;
}

.error-message {
    color: #ed4300;
    font-size: 14px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 10px;
}

.floating-button {
    position: fixed;
    bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 20px;
}

.marginVertical {
    margin-top: 5px;
    margin-bottom: 5px;
}

.location-permission-modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // z-index: 99999;

    .location-permission-modal {
        // height: 100%;
        width: 85%;
        background-color: white;
        border-radius: 8px;
        padding: 20px;
        z-index: 99;

        .location-modal {
            width: 100%;
            input {
                border-radius: 10px;
                border: 1px solid lightgray;
                padding: 8px 10px;
                width: 100%;
                margin-top: 10px;
                margin-bottom: 20px;
                box-sizing: border-box;

                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.input {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #525252;

    outline: none;
}
